import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/basic.scss";

export const BasicPageTemplate = props => {
  const { page } = props;

  return (
    <article className="basic">
      <div className="basic-container  container">
        <section className="basic-header">
          <div className="basic-titleWrapper">
            <h1 className="basic-title">{page.frontmatter.title}</h1>
          </div>
        </section>
        <section>
          {/* The page.html is actually markdown when viewing the page from the netlify CMS,
              so we must use the ReactMarkdown component to parse the mardown in that case  */}
          {page.bodyIsMarkdown ? (
            <ReactMarkdown className="basic-description" source={page.html} />
          ) : (
            <HTMLContent className="basic-description" content={page.html} />
          )}
        </section>
      </div>
    </article>
  );
};

const BasicPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    }
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <BasicPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

BasicPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default BasicPage;

export const basicPageQuery = graphql`
  query BasicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
